import axios from "axios";

export const checkEmailExists = async (email) => {
    
  const config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `https://dev.fasttrackr.in/check-email/${email}`,
    headers: {},
  };

  try {
    const response = await axios.request(config);
    console.log("response- checkmail->", response.data);
    return response.data.exists; 
  } catch (error) {
    console.error("checkEmailExists - error->", error);
    return false; 
  }
};

export const addBetaForExplore = async (email, phone, country, name) => {
  const config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `https://artgen.fasttrackr.in/addbeta?phone=${phone}&email=${email}&country=${country}&name=${name}`,
    headers: {},
  };

  try {
    const response = await axios.request(config);
    console.log("response -addBetaForExplore" + JSON.stringify(response));
  } catch (error) {
    throw error;
  }
};

export const addBetaForSilent = async (email, phone, country, name) => {
  const config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `https://artgen.fasttrackr.in/addbetasilent?phone=${phone}&email=${email}&country=${country}&name=${name}`,
    headers: {},
  };
  try {
    const response = await axios.request(config);
    console.log("response -addBetaForSilent" + JSON.stringify(response));
  } catch (error) {
    throw error;
  }
};

export const fetchLocation = async () => {
  try {
    const response = await axios.get("https://ipapi.co/json/");
    return response.data;
  } catch (error) {
    throw error;
  }
};
