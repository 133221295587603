import React, { useState, useEffect } from 'react';
import { db } from "../firebase/firebaseConfig";
import { updateDoc, doc, getDoc, setDoc, Timestamp } from "firebase/firestore";
import { useNavigate, useLocation } from "react-router-dom";
import { useFormContext } from "../context/context";
import { addBetaForExplore, addBetaForSilent, fetchLocation } from "../Utils/api";
import Modal from "./Modal";
import "../styles/global.css";

const SelectOnboardingMethod = () => {
  const { setFormData } = useFormContext();
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isSelfOnboardingPopupOpen, setIsSelfOnboardingPopupOpen] = useState(false);
  const [isAssistedOnboardingPopupOpen, setIsAssistedOnboardingPopupOpen] = useState(false);
  const [savedFormData, setSavedFormData] = useState(null);
  const [errors, setErrors] = useState({});
  const [hasPermission, setHasPermission] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const updateUserEmail = async () => {
      try {
        const queryParams = new URLSearchParams(location.search);
        const email = queryParams.get("email");
        console.log("Email from query params:", email);

        const storedFormData = localStorage.getItem('onboardingFormData');
        const parsedFormData = JSON.parse(storedFormData);
        if (storedFormData) {
          console.log("Form data from localStorage:", parsedFormData);
          setSavedFormData(parsedFormData);
          setFormData(parsedFormData);
        } else {
          console.log("No form data found in localStorage");
          throw new Error("No form data found");
        }

        if (email && parsedFormData.whatsapp && parsedFormData.countryCode) {
          const phoneNumber = parsedFormData.countryCode.replace("+", "") + parsedFormData.whatsapp;
          await updateEmailInFirestore(email, phoneNumber);
          setSavedFormData(prevData => ({ ...prevData, email }));
          setFormData(prevData => ({ ...prevData, email }));
        } else {
          throw new Error("Missing email or phone information");
        }

        setModalMessage("Email updated successfully!");
      } catch (error) {
        console.error("Error updating user email:", error);
        setModalMessage("Error updating email. Please try again.");
        setIsModalOpen(true);
        setHasPermission(false);
        setTimeout(() => navigate('/'), 3000);
      } finally {
        setLoading(false);
      }
    };

    updateUserEmail();
  }, []);

  const updateEmailInFirestore = async (email, phoneNumber) => {
    const docRef = doc(db, "onboarding-users", phoneNumber);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      await updateDoc(docRef, { email: email });
      console.log("User email updated in Firestore successfully");
    } else {
      throw new Error("User document not found in Firestore");
    }
  };

  const handleClick = async (option) => {
    setLoading(true);

    try {
      const data = await fetchLocation();
      const phoneNumber = savedFormData.countryCode.replace("+", "") + savedFormData.whatsapp;

      const finalData = {
        countryCode: savedFormData.countryCode,
        whatsapp: savedFormData.whatsapp,
        name: savedFormData.name,
        onboardingType: option,
        phone: phoneNumber,
        email: savedFormData.email,
        createdAt: Timestamp.now(),
        locationInfo: {
          ip: data.ip,
          city: data.city,
          region: data.region,
          country: data.country,
          postal: data.postal,
          latitude: data.latitude,
          longitude: data.longitude,
          timezone: data.timezone,
        },
        occupation:
          savedFormData.occupation === "Others"
            ? savedFormData.otherOccupation
            : savedFormData.occupation,
      };

      const docRef = doc(db, "onboarding-users", phoneNumber);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        await updateDoc(docRef, finalData);
        console.log("Onboarding data successfully updated for:", phoneNumber);
      } else {
        await setDoc(docRef, finalData);
        console.log("Onboarding data successfully saved for:", phoneNumber);
      }

      if (option === "self-onboarding") {
        await addBetaForExplore(savedFormData.email, phoneNumber, data.timezone, finalData.name);
        navigate("/success");
      } else {
        try {
          await addBetaForSilent(savedFormData.email, phoneNumber, data.timezone, finalData.name);
          window.location.href = "https://calendly.com/prads-fasttrackr/onboarding";
        } catch (error) {
          console.error("Error adding beta user for silent onboarding:", error);
          setModalMessage("An error occurred while setting up your onboarding. Please try again.");
          setIsModalOpen(true);
        }
      }
    } catch (error) {
      console.error("handleClick error:", error);
      setModalMessage("An error occurred. Please try again.");
      setIsModalOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleSelfOnboardingClick = () => {
    setIsSelfOnboardingPopupOpen(true);
  };

  const handleRecommendedClick = () => {
    setIsAssistedOnboardingPopupOpen(true);
  };

  const handleAssistedOnboardingConfirm = async () => {
    try {
      setIsAssistedOnboardingPopupOpen(false);
      await handleClick("recommended");
    } catch (error) {
      console.error("Error while onboarding - Recommended:", error);
      setModalMessage("An error occurred. Please try again.");
      setIsModalOpen(true);
    }
  };

  const handleSelfOnboardingConfirm = async () => {
    try {
      setIsSelfOnboardingPopupOpen(false);
      await handleClick("self-onboarding");
    } catch (error) {
      console.error("Error while onboarding - Self:", error);
      setModalMessage("An error occurred. Please try again.");
      setIsModalOpen(true);
    }
  };

  return (
    <div className="logo-card-container">
      <div className="logo-container">
        <img src="/images/ftlogo.svg" alt="FastTrackr" className="logo" />
      </div>
      {loading ? (
        <div
        className="container"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#f9fafc",
          flexDirection: "column",
          fontFamily: "Roboto, sans-serif",
        }}
      >
        <img
          src="/images/ftlogo.svg"
          alt="FastTrackr Logo"
          style={{
            width: "250px",
            height: "150px",
            animation: "blink 1s infinite",
          }}
        />
        <h2
          style={{
            marginTop: "20px",
            color: "#2c3e50",
            fontSize: "22px",
            fontWeight: "500",
          }}
        >
          Just a moment...
        </h2>
        <p
          style={{
            color: "#7f8c8d",
            fontSize: "16px",
            marginTop: "10px",
            letterSpacing: "0.5px",
          }}
        >
          We're loading your content.
        </p>
      </div>
      ) : (
        <>
          {hasPermission ? (
            <div className="container">
              <h2 className="header">Your Payment is Successful!</h2>
              
              <form>
                <div className="input-group">
                  <label className="label">Select one of the onboarding options:</label>
                </div>
                <OnboardingOptions 
                  handleRecommendedClick={handleRecommendedClick}
                  handleSelfOnboardingClick={handleSelfOnboardingClick}
                />
              </form>

              {isAssistedOnboardingPopupOpen && (
                <div className="popup-overlay">
                  <div className="popup-content">
                    <div className="head">
                      <h3 style={{marginTop:"5px"}}>You have selected assisted onboarding, are you sure?</h3>
                      <h4>Next screen will take you to the Calendly page to book a
                      15-minute onboarding call</h4>
                    </div>
                    <div className="popup-buttons">
                      <button
                        className="button-accept"
                        onClick={handleAssistedOnboardingConfirm}
                      >
                        I understand
                      </button>
                      <button
                        className="button-cancel"
                        onClick={() => setIsAssistedOnboardingPopupOpen(false)}
                      >
                        Go back
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {isSelfOnboardingPopupOpen && (
                <div className="popup-overlay">
                  <div className="popup-content">
                    <div className="head">
                      <h3 style={{marginTop:"5px"}}>You have selected self-onboarding, are you sure?</h3>
                      <h4>Next screen will take you to your FastTrackr AI assistant on
                      WhatsApp</h4>
                    </div>
                    <div className="popup-buttons">
                      <button
                        className="button-accept"
                        onClick={handleSelfOnboardingConfirm}
                      >
                        I understand
                      </button>
                      <button
                        className="button-cancel"
                        onClick={() => setIsSelfOnboardingPopupOpen(false)}
                      >
                        Go back
                      </button>
                    </div>
                  </div>
                </div>
              )}
              
              <Modal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                message={modalMessage}
              />
            </div>
          ) : (
            <div className="container">
              <div className="permission-denied-card">
                <div className="icon-container">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="48"
                    height="48"
                    viewBox="0 0 24 24"
                  >
                    <circle cx="12" cy="12" r="10" fill="#ff4d4f" />
                    <path
                      d="M8 8l8 8M8 16l8-8"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <h2>You don't have permission to access this page</h2>
                <p>Please reach out to FastTrackr AI team for assistance.</p>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

const OnboardingOptions = ({ handleRecommendedClick, handleSelfOnboardingClick }) => (
  <div className="onboarding-options">
    <div
      className="card recommended"
      onClick={handleRecommendedClick}
      style={{ cursor: 'pointer' }}
    >
      <span className="label">Recommended</span>
      <h2>Assisted Onboarding</h2>
      <p className="text-white">FastTrackr expert will show you around</p>
    </div>

    <div
      className="card explore"
      onClick={handleSelfOnboardingClick}
      style={{ cursor: 'pointer' }}
    >
      <h2>Self-onboarding</h2>
      <p className="text-white">I'll do it myself</p>
    </div>
  </div>
);

export default SelectOnboardingMethod;
