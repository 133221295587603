import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// const firebaseConfig = {
//   // Local Firebase
//   // apiKey: "AIzaSyBgKZx0Q414bsSlNMxxdPQ3xEf0E5CnLpE",
//   // authDomain: "meeting-scheduler-e2171.firebaseapp.com",
//   // projectId: "meeting-scheduler-e2171",
//   // storageBucket: "meeting-scheduler-e2171.appspot.com",
//   // messagingSenderId: "168649112748",
//   // appId: "1:168649112748:web:0a372069ba288c7f54f309",
//   // measurementId: "G-P874LK8FS0",
// //Server Firebase
// apiKey: "AIzaSyApSNbP6t-MbotAXuRe5Um8S1ausOZYz6k",
// authDomain: "medamax-68958.firebaseapp.com",
// projectId: "medamax-68958",
// storageBucket: "medamax-68958.appspot.com",
// messagingSenderId: "9107551634",
// appId: "1:9107551634:web:eae09c4732fc272f913d4b",
// measurementId: "G-QJFGYG9Z9E"
// };

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};


const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
export { db };
