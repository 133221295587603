import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { FormProvider } from "./context/context";
import OnboardingForm from "./components/OnBoardingForm";
import SuccessMessage from './components/SuccessMessage/SuccessMessage';
import SelectOnboardingMethod from "./components/SelectOnboardingMethod";
import PaymentOptions from "./components/PaymentOptions";

function App() {
  return (
    <FormProvider>
      <Router>
        <Routes>
          <Route path="/" element={<OnboardingForm />} />
          <Route path="/success" element={<SuccessMessage />} />
          <Route path="/onboard-method" element={<SelectOnboardingMethod />} />
          <Route path="/payment-plans" element={<PaymentOptions />} />
        </Routes>
      </Router>
    </FormProvider>
  );
}

export default App;


