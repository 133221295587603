import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useFormContext } from "../context/context";
import { db } from "../firebase/firebaseConfig";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { fetchLocation } from "../Utils/api";
import CountryCodeDropdown from "./CountryCodeDropdown";
import Modal from "./Modal";
import "../styles/global.css";

const OnboardingForm = () => {
  const { formData, setFormData, handleChange } = useFormContext();
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [errors, setErrors] = useState({
    whatsapp: "",
    countryCode: "",
    name: "",
    occupation: "",
    otherOccupation: "",
    referralCode: "",
  });

  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const referralCode = queryParams.get("referral");
    if (referralCode) {
      setFormData(prevData => ({ ...prevData, referralCode }));
    }
  }, [location, setFormData]);

  const validateWhatsAppNumber = (number) => {
    const numberPattern = /^\d+$/;
    return numberPattern.test(number);
  };

  const validateForm = () => {
    let isValid = true;
    let newErrors = { ...errors };

    if (!validateWhatsAppNumber(formData.whatsapp)) {
      newErrors.whatsapp = "Please enter a valid phone number.";
      isValid = false;
    }

    if (formData.countryCode === "") {
      newErrors.countryCode = "Please select a valid country code.";
      isValid = false;
    }

    if (formData.name.trim() === "") {
      newErrors.name = "Name cannot be empty.";
      isValid = false;
    } else if (!/^[a-zA-Z\s]+$/.test(formData.name)) {
      newErrors.name = "Name should contain only letters and spaces.";
      isValid = false;
    }

    if (formData.occupation === "") {
      newErrors.occupation = "Please select an occupation.";
      isValid = false;
    }

    if (formData.occupation === "Others" && formData.otherOccupation.trim() === "") {
      newErrors.otherOccupation = "Please specify your occupation.";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ 
      ...prev, 
      [name]: value,
      otherOccupation: value !== "Others" ? "" : prev.otherOccupation 
    }));
    setErrors((prev) => ({ ...prev, occupation: "" }));
  };

  const validateReferralCode = async (code) => {
    if (!code) return true;

    setLoading(true);
    try {
      const referralDocRef = doc(db, "fasttrackr-codes", code);
      const referralDocSnap = await getDoc(referralDocRef);

      if (referralDocSnap.exists()) {
        await addUserToFirestore();
        return true;
      } else {
        setErrors(prev => ({ ...prev, referralCode: "Invalid referral code" }));
        setModalMessage("Invalid referral code. Please check and try again.");
        setIsModalOpen(true);
        return false;
      }
    } catch (error) {
      console.error("Error checking referral code:", error);
      setErrors(prev => ({ ...prev, referralCode: "Error checking referral code" }));
      setModalMessage("Error checking referral code. Please try again later.");
      setIsModalOpen(true);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const checkPhoneNumberExists = async (phoneNumber) => {
    const docRef = doc(db, "onboarding-users", phoneNumber);
    const docSnap = await getDoc(docRef);
    return docSnap.exists();
  };

  const addUserToFirestore = async () => {
    const phoneNumber = formData.countryCode.replace("+", "") + formData.whatsapp;
    const locationData = await fetchLocation();
    
    const userData = {
      name: formData.name,
      phone: phoneNumber,
      whatsapp: formData.whatsapp,
      countryCode: formData.countryCode,
      occupation: formData.occupation,
      otherOccupation: formData.otherOccupation,
      referralCode: formData.referralCode,
      createdAt: new Date(),
      locationInfo: {
        ip: locationData.ip,
        city: locationData.city,
        region: locationData.region,
        country: locationData.country,
        postal: locationData.postal,
        latitude: locationData.latitude,
        longitude: locationData.longitude,
        timezone: locationData.timezone,
      }
    };

    try {
      await setDoc(doc(db, "onboarding-users", phoneNumber), userData);
      console.log("User data added to Firestore successfully");
    } catch (error) {
      console.error("Error adding user data to Firestore:", error);
      throw error;
    }
  };

  const handleContinue = async () => {
    if (!validateForm()) return;

    const phoneNumber = formData.countryCode.replace("+", "") + formData.whatsapp;

    setLoading(true);
    try {
      const phoneExists = await checkPhoneNumberExists(phoneNumber);
      if (phoneExists) {
        setModalMessage("This phone number is already registered. Please use a different number.");
        setIsModalOpen(true);
        return;
      }

      if (!await validateReferralCode(formData.referralCode)) return;

      await addUserToFirestore();

      localStorage.setItem('onboardingFormData', JSON.stringify(formData));

      // const developmentPaymentUrl = `https://fasttrackrai.lemonsqueezy.com/buy/2ac1528e-e352-4b0e-9747-0527f66205ec`;
      const prodPaymentUrl = `https://fasttrackrai.lemonsqueezy.com/buy/3f89f721-2b26-4a77-8bef-dc7e93668a34?media=0`
      window.location.href = prodPaymentUrl;
    } catch (error) {
      console.error("Error during form submission:", error);
      setModalMessage("An error occurred. Please try again later.");
      setIsModalOpen(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="logo-card-container">
      <div className="logo-container">
        <img src="/images/ftlogo.svg" alt="FastTrackr" className="logo" />
      </div>
      <div className="container">
        <h2 className="header">Referral Form</h2>
        <form>
          <WhatsAppInput 
            formData={formData} 
            handleChange={handleChange} 
            setFormData={setFormData}
            errors={errors}
            setErrors={setErrors}
          />
          <NameInput 
            formData={formData} 
            handleChange={handleChange} 
            errors={errors}
          />
          <OccupationInput 
            formData={formData} 
            handleRadioChange={handleRadioChange}
            handleChange={handleChange}
            errors={errors}
          />
          <ReferralCodeInput 
            formData={formData} 
            handleChange={handleChange} 
            errors={errors}
          />
          <button 
            type="button" 
            className="continue-button" 
            onClick={handleContinue}
            disabled={loading}
          >
            {loading ? "Checking..." : "Continue to Payment"}
          </button>
        </form>
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        message={modalMessage}
      />
    </div>
  );
};

const WhatsAppInput = ({ formData, handleChange, setFormData, errors, setErrors }) => (
  <div className="input-group">
    <label className="label">
      WhatsApp Number:
      <span className="info-button" aria-label="WhatsApp Number Info">
        i
        <span className="info-tooltip">
          Please enter your WhatsApp number and country code.
        </span>
      </span>
    </label>
    <div className="whatsapp-input-group">
      <CountryCodeDropdown
        selectedCode={formData.countryCode}
        setSelectedCode={(value) => setFormData({ ...formData, countryCode: value })}
        setErrors={setErrors}
      />
      <input
        type="text"
        name="whatsapp"
        value={formData.whatsapp}
        onChange={handleChange}
        className="whatsapp-input"
        required
        maxLength={20}
        placeholder="Enter WhatsApp number"
      />
    </div>
    {errors.whatsapp && <p style={{ color: "red" }}>{errors.whatsapp}</p>}
    {errors.countryCode && <p style={{ color: "red" }}>{errors.countryCode}</p>}
  </div>
);

const NameInput = ({ formData, handleChange, errors }) => (
  <div className="input-group">
    <label className="label">
      Name:
      <span className="info-button" aria-label="Name Info">
        i<span className="info-tooltip">Enter your name.</span>
      </span>
    </label>
    <input
      type="text"
      name="name"
      value={formData.name}
      onChange={handleChange}
      className="input"
      required
      placeholder="Enter name"
    />
    {errors.name && <p style={{ color: "red" }}>{errors.name}</p>}
  </div>
);

const OccupationInput = ({ formData, handleRadioChange, handleChange, errors }) => (
  <div className="input-group">
    <label className="label">
      Occupation:
      <span className="info-button" aria-label="Occupation Info">
        i
        <span className="info-tooltip">
          Choose your current job title.
        </span>
      </span>
    </label>
    <div className="radio-group">
      {["VC/PE", "Founder/Entrepreneur", "Leadership team/CXO", "VP/SVP/Manager", "Tech/AI Enthusiast", "Others"].map((option) => (
        <label className="radio-label" key={option}>
          <input
            type="radio"
            name="occupation"
            value={option}
            checked={formData.occupation === option}
            onChange={handleRadioChange}
            required
            className="radio-input"
          />
          {option}
        </label>
      ))}
      {formData.occupation === "Others" && (
        <input
          type="text"
          name="otherOccupation"
          value={formData.otherOccupation}
          onChange={handleChange}
          className="input"
          placeholder="Enter data in the text field"
        />
      )}
    </div>
    {errors.occupation && <p style={{ color: "red" }}>{errors.occupation}</p>}
    {formData.occupation === "Others" && errors.otherOccupation && (
      <p style={{ color: "red" }}>{errors.otherOccupation}</p>
    )}
  </div>
);

const ReferralCodeInput = ({ formData, handleChange, errors }) => (
  <div className="input-group">
    <label className="label">
      Referral Code:
      <span className="info-button" aria-label="Referral Code Info">
        i
        <span className="info-tooltip">
          Referral code (if provided) has been automatically filled.
        </span>
      </span>
    </label>
    <input
      type="text"
      name="referralCode"
      value={formData.referralCode}
      onChange={handleChange}
      className="input"
      placeholder="Referral code"
    />
    {errors.referralCode && <p style={{ color: "red" }}>{errors.referralCode}</p>}
  </div>
);

export default OnboardingForm;
